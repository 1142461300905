import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ModuleKind } from 'app/library/module/enums/module-kind.enum';
import { ToDoType } from 'app/projects/user/src/lib/enums/to-do.enum';

@Pipe({
    name: 'getToDoAction',
})
@Injectable()
export class GetToDoActionPipe implements PipeTransform {
    ModuleKind = ModuleKind;

    transform(type: ToDoType, moduleKind: ModuleKind): string {
        switch (type) {
            case ToDoType.CERTIFICATE_TO_DO:
                return 'TODOS.ACTION.RENEW_CERTIFICATE';
            case ToDoType.MODULE_TO_DO:
                return moduleKind === ModuleKind.ProgramExecution || moduleKind === ModuleKind.ProgramTemplate ? 'TODOS.ACTION.COMPLETE_PROGRAM' : 'TODOS.ACTION.COMPLETE_COURSE';
            case ToDoType.SURVEY_TO_DO:
                return 'TODOS.ACTION.COMPLETE_SURVEY';
            case ToDoType.QUIZ_TO_DO:
                return 'TODOS.ACTION.COMPLETE_QUIZ';
            default:
                return '';
        }
    }
}
