import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FuseConfirmDialogModule } from 'app/projects/fuse/src/lib/components/confirm-dialog/confirm-dialog.module';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { FlatButtonComponent } from './components/buttons/flat-button/flat-button.component';
import { IconButtonComponent } from './components/buttons/icon-button/icon-button.component';
import { MenuItemButtonComponent } from './components/buttons/menu-item-button/menu-item-button.component';
import { WhiteButtonComponent } from './components/buttons/white-button/white-button.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { AutocompleteDialogComponent } from './components/dialogs/autocomplete/autocomplete.component';
import { BasicUsersListDialogComponent } from './components/dialogs/basic-users-list/basic-users-list.component';
import { MaxBrainConfirmCheckboxDialogComponent } from './components/dialogs/confirm-checbox/confirm-checkbox.component';
import { MaxBrainConfirmNotifyDialogComponent } from './components/dialogs/confirm-notify/confirm-notify.component';
import { MaxBrainConfirmDialogComponent } from './components/dialogs/confirm/confirm.component';
import { CopyToClipboardDialogComponent } from './components/dialogs/copy-to-clipboard/copy-to-clipboard.component';
import { MaxBrainEditorDialogComponent } from './components/dialogs/editor/editor.component';
import { MoveToDialogComponent } from './components/dialogs/move-to-dialog/move-to-dialog.component';
import { NameDialogComponent } from './components/dialogs/name/name.component';
import { RetrySavingDialogComponent } from './components/dialogs/retry-saving/retry-saving-dialog.component';
import { ScheduleSubjectTextDialogComponent } from './components/dialogs/schedule-subject-text/schedule-subject-text.component';
import { SubjectTextDialogComponent } from './components/dialogs/subject-text/subject-text.component';
import { InlineRenameComponent } from './components/inline-rename/inline-rename.component';
import { MSOfficeWebViewerComponent } from './components/ms-office-web-viewer/ms-office-web-viewer.component';
import { PdfWebViewerComponent } from './components/pdf-web-viewer/pdf-web-viewer.component';
import { ProfilePhotoActionWrapperComponent } from './components/profile-photo-action-wrapper/profile-photo-action-wrapper.component';
import { ProfilePhotoActionWrapperService } from './components/profile-photo-action-wrapper/profile-photo-action-wrapper.service';
import { ProfilePhotoEditorComponent } from './components/profile-photo-editor/profile-photo-editor.component';
import { SearchOutlineComponent } from './components/search-outline/search-outline.component';
import { SmartBannerComponent } from './components/smart-banner/smart-banner.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { MatButtonLoadingDirective } from './directives/mat-button-loading.directive';
import { NoWhitespaceDirective } from './directives/no-white-space-directive.directive';
import { OnlineStateIndicatorDirective } from './directives/online-state-indicator.directive';
import { CalculateDigicompDurationPipe } from './pipes/calculate-digicomp-duration.pipe';
import { CalculateDurationPipe } from './pipes/calculate-duration.pipe';
import { ColorLuminancePipe } from './pipes/color-luminance.pipe';
import { ExcludePipe } from './pipes/exclude.pipe';
import { ExcpectedDurationPipe } from './pipes/expected-duration.pipe';
import { FeatureSwitchPipe } from './pipes/feature-switch.pipe';
import { FilterLanguagesPipe } from './pipes/filter-languages.pipe';
import { FilterTagsPipe } from './pipes/filterTags.pipe';
import { FilterTagsByCategoryPipe } from './pipes/filterTagsByCategory.pipe';
import { FormatTimeSpentPipe } from './pipes/format-time-spent.pipe';
import { GetModuleIconPipe } from './pipes/getModuleIcon.pipe';
import { GetQuestionIconPipe } from './pipes/getQuestionIcon.pipe';
import { GetStatusPipe } from './pipes/getStatus.pipe';
import { GetStatusByValuePipe } from './pipes/getStatusByValue.pipe';
import { GetUserExtendedAttributeValuePipe } from './pipes/getUserExtendedAttributeValue.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrderByBooleanPipe } from './pipes/orderByBoolean.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { ScheduleExecDatePipe } from './pipes/schedule-exec-date.pipe';
import { ScheduleIconPipe } from './pipes/schedule-icon.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { HelperService } from './services/helper';
import { GetModuleKindLabelPipe } from './pipes/getModuleKindLabel.pipe';
import { GetToDoActionPipe } from './pipes/getToDoAction.pipe';
import { IsLargeScreenPipe } from './pipes/isLargeScreen.pipe';

@NgModule({
    imports: [
        EditorModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        TranslateModule.forChild({
            extend: true,
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatToolbarModule,
        MatCardModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatTableModule,
        MatMenuModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        NgxCleaveDirectiveModule,
        MatRadioModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatListModule,
        MatTreeModule,
    ],
    declarations: [
        MaxBrainConfirmDialogComponent,
        MaxBrainConfirmCheckboxDialogComponent,
        NameDialogComponent,
        MaxBrainEditorDialogComponent,
        MSOfficeWebViewerComponent,
        PdfWebViewerComponent,
        AutocompleteDialogComponent,
        InlineRenameComponent,
        SubjectTextDialogComponent,
        CopyToClipboardDialogComponent,
        ExcludePipe,
        HighlightPipe,
        LanguagePipe,
        FilterTagsByCategoryPipe,
        FilterTagsPipe,
        OrderByPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        InitialsPipe,
        SearchPipe,
        GetStatusPipe,
        GetStatusByValuePipe,
        CalculateDurationPipe,
        CalculateDigicompDurationPipe,
        ExcpectedDurationPipe,
        OrderByBooleanPipe,
        SmartBannerComponent,
        TimeInputComponent,
        MatButtonLoadingDirective,
        NoWhitespaceDirective,
        GetModuleIconPipe,
        FeatureSwitchPipe,
        ScheduleSubjectTextDialogComponent,
        ScheduleIconPipe,
        ScheduleExecDatePipe,
        InlineRenameComponent,
        BasicUsersListDialogComponent,
        OnlineStateIndicatorDirective,
        FilterLanguagesPipe,
        ColorLuminancePipe,
        GetUserExtendedAttributeValuePipe,
        ProfilePhotoEditorComponent,
        ProfilePhotoActionWrapperComponent,
        ColorLuminancePipe,
        MoveToDialogComponent,
        FormatTimeSpentPipe,
        GetQuestionIconPipe,
        MaxBrainConfirmNotifyDialogComponent,
        WhiteButtonComponent,
        DialogHeaderComponent,
        CertificateComponent,
        SearchOutlineComponent,
        FlatButtonComponent,
        IconButtonComponent,
        MenuItemButtonComponent,
        GetToDoActionPipe,
        RetrySavingDialogComponent,
        GetModuleKindLabelPipe,
        IsLargeScreenPipe,
    ],
    entryComponents: [
        MatProgressSpinner,
        MaxBrainConfirmDialogComponent,
        MaxBrainConfirmCheckboxDialogComponent,
        NameDialogComponent,
        MaxBrainEditorDialogComponent,
        AutocompleteDialogComponent,
        InlineRenameComponent,
        SubjectTextDialogComponent,
        CopyToClipboardDialogComponent,
        BasicUsersListDialogComponent,
        MoveToDialogComponent,
        MaxBrainConfirmNotifyDialogComponent,
        RetrySavingDialogComponent,
    ],
    providers: [
        ExcludePipe,
        HighlightPipe,
        LanguagePipe,
        FilterTagsByCategoryPipe,
        FilterTagsPipe,
        OrderByPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        InitialsPipe,
        SearchPipe,
        GetStatusPipe,
        GetStatusByValuePipe,
        CalculateDurationPipe,
        CalculateDigicompDurationPipe,
        ExcpectedDurationPipe,
        OrderByBooleanPipe,
        GetModuleIconPipe,
        FeatureSwitchPipe,
        InlineRenameComponent,
        FilterLanguagesPipe,
        GetUserExtendedAttributeValuePipe,
        ProfilePhotoActionWrapperService,
        ColorLuminancePipe,
        HelperService,
        FormatTimeSpentPipe,
        GetQuestionIconPipe,
        GetToDoActionPipe,
        IsLargeScreenPipe,
        GetModuleKindLabelPipe,
    ],
    exports: [
        FuseSharedModule,
        MaxBrainConfirmDialogComponent,
        MaxBrainConfirmCheckboxDialogComponent,
        NameDialogComponent,
        MaxBrainEditorDialogComponent,
        MSOfficeWebViewerComponent,
        PdfWebViewerComponent,
        AutocompleteDialogComponent,
        InlineRenameComponent,
        SubjectTextDialogComponent,
        CopyToClipboardDialogComponent,
        ExcludePipe,
        HighlightPipe,
        LanguagePipe,
        FilterTagsByCategoryPipe,
        FilterTagsPipe,
        OrderByPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        InitialsPipe,
        SearchPipe,
        GetStatusPipe,
        GetStatusByValuePipe,
        CalculateDurationPipe,
        CalculateDigicompDurationPipe,
        ExcpectedDurationPipe,
        OrderByBooleanPipe,
        SmartBannerComponent,
        TimeInputComponent,
        MatButtonLoadingDirective,
        NoWhitespaceDirective,
        TranslateModule,
        GetModuleIconPipe,
        FeatureSwitchPipe,
        ScheduleIconPipe,
        ScheduleExecDatePipe,
        BasicUsersListDialogComponent,
        OnlineStateIndicatorDirective,
        FilterLanguagesPipe,
        GetUserExtendedAttributeValuePipe,
        ProfilePhotoEditorComponent,
        ProfilePhotoActionWrapperComponent,
        ColorLuminancePipe,
        MoveToDialogComponent,
        FormatTimeSpentPipe,
        GetQuestionIconPipe,
        MaxBrainConfirmNotifyDialogComponent,
        WhiteButtonComponent,
        DialogHeaderComponent,
        CertificateComponent,
        SearchOutlineComponent,
        FlatButtonComponent,
        IconButtonComponent,
        MenuItemButtonComponent,
        GetToDoActionPipe,
        IsLargeScreenPipe,
        RetrySavingDialogComponent,
        IsLargeScreenPipe,
        GetModuleKindLabelPipe,
    ],
})
export class MaxBrainSharedModule {}
