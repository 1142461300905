<app-dialog-header [title]="'ENGAGE.AUDIENCE.DIALOGS.NEW_AUDIENCE.TITLE'" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div class="p-24" fxLayout="column" fxLayoutAlign="start">
    <form class="new-audience-form" [formGroup]="formGroup">
        <mat-form-field>
            <mat-label>{{ 'ENGAGE.AUDIENCE.TITLE.SINGULAR' | translate }}</mat-label>
            <mat-select formControlName="audience">
                <mat-option [value]="audienceListTree" [disabled]="isRootDisabled">{{ audienceListTree.name }}</mat-option>
                <ng-container *ngTemplateOutlet="listItem; context: { $implicit: audienceListTree.children, parents: [] }"> </ng-container>
                <ng-template #listItem let-children let-parents="parents">
                    <div *ngFor="let audience of children; let j = index">
                        <mat-optgroup *ngIf="!audience.isAutoGenerated">
                            <mat-option [class.selected]="audience.open" [value]="audience"> {{ audience.name }} </mat-option>
                            <ng-container *ngIf="audience.children.length">
                                <div class="audience-nested" [class.selected]="audience.open" [style.padding-left]="audience.children.length ? '25px' : '0px'">
                                    <ng-container *ngTemplateOutlet="listItem; context: { $implicit: audience.children, parents: parents.concat([j]) }"> </ng-container>
                                </div>
                            </ng-container>
                        </mat-optgroup>
                    </div>
                </ng-template>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="'GENERAL.BUTTON.ADD'" [disabled]="!formGroup.valid" (onClick)="onAddNewAudience()"></app-flat-button>
</div>
